import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogListItem = ({ post }) => {
  const title = post.frontmatter.title || post.fields.slug

  return (
    <li key={post.fields.slug}>
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h2>
            <Link to={post.fields.slug} itemProp="url">
              <span itemProp="headline">{title}</span>
            </Link>
          </h2>
          <small>From {post.frontmatter.author}</small>
        </header>
      </article>
    </li>
  )
};

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.everybodyElse.nodes
  const kateAndBobbyPosts = data.kateAndBobbyOnly.nodes;

  if (posts.length === 0 && kateAndBobbyPosts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All recipes" />
        <p>
          No blog posts found.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All recipes" />
      <ol style={{ listStyle: `none` }}>
        {kateAndBobbyPosts.map(post => (<BlogListItem post={post} />))}
      </ol>
      <hr />
      <ol style={{ listStyle: `none` }}>
        {posts.map(post => (<BlogListItem post={post} />))}
      </ol>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    everybodyElse: allMarkdownRemark(filter: {frontmatter: {author: {ne: "Kate & Bobby"}}}, sort: {fields: frontmatter___author}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          author
        }
      }
    }
    kateAndBobbyOnly: allMarkdownRemark(filter: {frontmatter: {author: {eq: "Kate & Bobby"}}}, sort: {fields: frontmatter___title}) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          author
        }
      }
    }
  }
`
